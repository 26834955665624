
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.search-user {
  &__item {

    &:not(:last-child) {
      margin-right: 32px;
    }

    &--wide {
      width: 440px;
    }
  }

  &__user-contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__user-name {
    margin-right: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
    transition: color $base-animation-time;
  }

  &__user-email {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-50;
    margin-right: 16px;
  }

  &__user-appointment {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-40;
  }
}
